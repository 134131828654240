<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    width="95%"
    :modal-append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :inline="true" class="demo-form-inline"  label-width="120px">
        <el-form-item label="账单名称：" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入账单名称" class="input-form-main"></el-input>
        </el-form-item>
        <el-form-item label="收款方：" prop="payee">
          <el-input v-model="formModel.payee" placeholder="请输入收款方" class="input-form-main"></el-input>
        </el-form-item>
        <el-form-item label="收款账户：" prop="collectionAccountId">
            <!-- <el-input v-model="formModel.collectionAccountId" style="display:none"></el-input>
            <el-input v-model="formModel.collectionAccountName" placeholder="请输入收款方" class="input-form-main" readonly="readonly"></el-input> -->
            <el-select v-model="formModel.collectionAccountId"   class="input-form-main" >
                <el-option
                    v-for="collectionAccount in collectionAccountList"
                    :key="collectionAccount.id"
                    :label="collectionAccount.name"
                    :value="collectionAccount.id"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="缴费单位：" prop="checkinInfoId">
          <el-select v-model="formModel.checkinInfoId" filterable class="input-form-main" @change="handleCompanyChange">
            <el-option
                v-for="checkinInfo in companyList"
                :key="checkinInfo.id"
                :label="checkinInfo.companyName"
                :value="checkinInfo.id"
              ></el-option>
          </el-select>
        </el-form-item> 
        <el-form-item label="推送用户：" prop="personName" >
        <!--
            <el-input v-model="formModel.personId" style="display:none"></el-input>
        -->
            <el-input v-model="formModel.personName" :disabled="true" class="input-form-main"></el-input> 
          <!-- <el-select v-model="formModel.personId" class="input-form-main" filterable>
            <el-option
                v-for="person in personList"
                :key="person.id"
                :label="person.name"
                :value="person.id"
              ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="备注：" prop="bak">
          <el-input type="textarea" v-model="formModel.bak" placeholder="请输入备注"  class="input-form-main"></el-input>
        </el-form-item>
        <el-form-item label="开票类型：" prop="invoiceType">
          <el-select v-model="formModel.invoiceType" filterable class="input-form-main">
            <el-option
                v-for="company in invoiceTypeList"
                :key="company.value"
                :label="company.name"
                :value="company.value"
              ></el-option>
          </el-select>
        </el-form-item> 
        <el-form-item label="微信支付：" prop="isOpenWechat" v-if="formModel.isMobilePayment">
            <el-switch
                v-model="formModel.isOpenWechat"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="handlChange(row)">
            </el-switch>
        </el-form-item>
        <el-form-item label="支付宝支付：" prop="isOpenAlipay" v-if="formModel.isMobilePayment">
            <el-switch
                v-model="formModel.isOpenAlipay"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="handlChange(row)">
            </el-switch>
        </el-form-item>

        <h3>账单信息</h3>
        <el-table 
        class="compact-table"
        :data="formModel.billPushGoodsList" 
        show-summary :summary-method="getSummaries">
            <el-table-column prop="sort" type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="goodsId" label="收费项目" width="150">
              <template slot-scope="scope">
                  <el-form-item
                    :prop="'billPushGoodsList.' + scope.$index + '.goodsId'"
                    :rules="ruleValidate['goodsId']"
                  >
                    <el-select 
                    v-model="scope.row.goodsId" 
                    placeholder="请选择" :filterable="true"
                    @change="handGoodsChange(scope)" size="mini">
                        <el-option
                        v-for="result in goodsList"
                        :key="result.id"
                        :label="result.goodsName"
                        :value="result.id"
                        ></el-option>
                    </el-select>
                  </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="beginEndTime" label="起止时间" width="380">
                <template slot-scope="scope">
                    <el-form-item
                    :prop="'billPushGoodsList.' + scope.$index + '.beginEndTime'"
                    :rules="ruleValidate['beginEndTime']"
                    >
                        <el-date-picker
                            v-model="scope.row.beginEndTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"
                            value-format="yyyy-MM-dd"
                            size="mini"
                            >
                        </el-date-picker>
                    </el-form-item>
                </template>
            </el-table-column>
            <el-table-column prop="numStr" label="数量"  width="310">
                <template slot-scope="scope">
                    <el-form-item
                    :prop="'billPushGoodsList.' + scope.$index + '.numStr3'"
                    :rules="ruleValidate['numStr3']"
                    >
                        <template v-if="scope.row.goodTypeId=='7'">
                            <el-input style="width:85px" placeholder="止码" v-model.number="scope.row.numStr2" @change="handStartChange(scope)" size="mini"></el-input>
                            -<el-input style="width:85px" placeholder="起码" v-model.number="scope.row.numStr" @change="handStartChange(scope)" size="mini"></el-input>
                            =<el-input style="width:85px" v-model.number="scope.row.numStr3"  @change="handNumChange(scope)" size="mini"></el-input>
                        </template>
                        <template v-else>
                            <el-input placeholder="数量" v-model="scope.row.numStr3"  @change="handNumChange(scope)" size="mini"></el-input>
                        </template>  
                    </el-form-item>
                </template>
            </el-table-column>
            <el-table-column prop="standard" label="收费标准" width="180">
                <template slot-scope="scope">
                    <el-form-item
                    :prop="'billPushGoodsList.' + scope.$index + '.unitPrice'"
                    :rules="ruleValidate['unitPrice']"
                    size="mini"
                    >
                        <el-input placeholder="收费标准" v-model="scope.row.unitPrice" @change="handNumChange(scope)" size="mini">
                            <template slot="append">{{scope.row.unit}}</template>
                        </el-input>
                    </el-form-item>
                </template>
            </el-table-column>
            <el-table-column prop="totalFee" label="合计金额(元)">
                <template slot-scope="scope">
                    <el-form-item
                    :prop="'billPushGoodsList.' + scope.$index + '.totalFee'"
                    :rules="ruleValidate['totalFee']" size="mini"
                    >
                    <el-input placeholder="合计金额" :value="scope.row.totalFee" readonly="readonly" size="mini">
                    </el-input>
                    </el-form-item>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="100" fixed="right">
              <template slot-scope="{row}">
                <el-form-item>
                  <i class="el-icon-delete my-font" @click="handleDelete(row)"></i>
                  <i class="el-icon-document-add my-font" @click="handleInsert(row)"></i>
                </el-form-item>
              </template>
            </el-table-column>

            <div slot="empty">
              <el-button icon="el-icon-circle-plus-outline" :plain="true" @click="handleAdd()">新增</el-button>
            </div>
        </el-table>
      </el-form>

        <div class="el-table__empty-block" v-if="formModel.billPushGoodsList.length!=0">
          <el-button icon="el-icon-circle-plus-outline" @click="handleAdd()">新增</el-button>
        </div>

    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">保存</el-button>
    </span>
    
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import billPushApi from "@/api/business/billPush";
import companyInfoApi from "@/api/base/companyInfo";
import companyPaymentApi from "@/api/base/companyPayment";
import companyGoodsInfoApi from "@/api/base/companyGoodsInfo";
import personInfoApi from "@/api/base/personInfo";
export default {
    props: ["businessKey", "title"],
    data() {
        return {
            ruleValidate: {
                name: [{ required: true, message: "账单名称不能为空", trigger: "blur" }],
                checkinInfoId: [{ required: true, message: "缴费单位不能为空", trigger: "blur" }],
                // payService: [{ required: true, message: "收费项目不能为空", trigger: "blur" }],
                invoiceType: [{ required: true, message: "开票类型不能为空", trigger: "blur" }],
                personName: [{ required: true, message: "推送用户不能为空", trigger: "blur" }],
                beginEndTime:[{ required: true, message: "起止时间不能为空", trigger: "blur" }],
                numStr3:[{ required: true, message: "数量不能为空", trigger: "blur" }],                
                goodsId:[{ required: true, message: "收费项目未选择", trigger: "blur" }]
            },
            formModel: {
                name:"",
                payee:"",
                companyId:"",
                // payService:"",
                collectionAccountId:"",
                collectionAccountName:"",
                bak:"",
                billPushGoodsList:[],
                removeBillPushGoodsList:[],
                personId:0,
                personName:"",
                checkinInfoId:"",
                isOpenWechat:false,
                isOpenAlipay:false,
            },
            beginEndTime: null,
            personList:[],
            goodsList:[],
            collectionAccountList:[],
            payServiceList:[],
            tableData:[],
            companyList:[],
            invoiceTypeList:[],
            showDialog: true,
            loading: false,
            submitting: false,
            props: {
                // 配置项（必选）
                value: "id",
                label: "name",
                children: "children"
            },
        }
    },
    created() {
        companyPaymentApi.getCompanyList().then((response)=>{
            var jsonData = response.data;
            this.companyList = jsonData.data;
        });

        companyPaymentApi.list().then((response)=>{
            var jsonData = response.data;
            this.collectionAccountList = jsonData.data;
        });

        billPushApi.invoiceTypeList().then((response)=>{
            var jsonData = response.data;
            this.invoiceTypeList = jsonData.data;
        });
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleCompanyChange(newVal){
            // this.formModel.personName = newVal;

            var self = this;

            var formData = new FormData();
            formData.append("checkinInfoId", newVal);

            companyGoodsInfoApi.findByCheckinInfoId(formData).then((response)=>{
                var jsonData = response.data;
                this.goodsList = jsonData.data;
            });

            companyPaymentApi.findByCheckInId(formData).then((response)=>{
                var jsonData = response.data;
                this.formModel.personId = jsonData.data.id;
                this.formModel.checkinInfoId = jsonData.data.checkinInfoId;
                
                self.$set(self.formModel, 'personName', jsonData.data.name);
            });
        },
        handleSubmit() {
            var self = this;

            this.$refs["form"].validate(valid => {
                if (valid) {
                    //增加转型,避免直接修改formModel
                    var requestData = JSON.parse(JSON.stringify(self.formModel));

                    var billPushGoodsList = requestData.billPushGoodsList;

                    if(billPushGoodsList.length==0) {
                        this.$message.warning("收费项目不能为空!");
                        return;
                    }

                    for(var i=0;i<billPushGoodsList.length;i++){
                        billPushGoodsList[i].sortNo = i+1;

                        if(billPushGoodsList[i].beginEndTime!=null){
                            billPushGoodsList[i].beginDate = billPushGoodsList[i].beginEndTime[0];
                            billPushGoodsList[i].endDate = billPushGoodsList[i].beginEndTime[1]
                            billPushGoodsList[i].beginEndTime=null;
                        }
                        
                        billPushGoodsList[i].standard = billPushGoodsList[i].unitPrice + billPushGoodsList[i].unit;
                    }

                    var removeBillPushGoodsList = requestData.removeBillPushGoodsList;

                    for(var j=0;j<removeBillPushGoodsList.length;j++){
                        removeBillPushGoodsList[j].sortNo = j+1;

                        if(removeBillPushGoodsList[j].beginEndTime!=null){
                            removeBillPushGoodsList[j].beginDate = removeBillPushGoodsList[j].beginEndTime[0];
                            removeBillPushGoodsList[j].endDate = removeBillPushGoodsList[j].beginEndTime[1];
                            removeBillPushGoodsList[j].beginEndTime=null;
                        }
                    }

                    (function() {
                        var id = requestData.id;
                        
                        if (id == null || id.length == 0) {
                        return billPushApi.add(requestData);
                        } else {
                        return billPushApi.update(requestData);
                        }
                    })().then(function(response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            self.$message({
                                message: "保存成功!",
                                type: "success"
                            });

                            self.$emit("close", true);
                        } else {
                            self.$message({
                                message: jsonData.message + "",
                                type: "warning"
                            });

                            self.$emit("close", false);
                        }
                    });
                }
            });
        },
        handleAdd() {
            this.formModel.billPushGoodsList.push({
                sortNo: this.formModel.billPushGoodsList.length + 1,
                goodsId: "",
                beginEndTime: null,
                numStr: "",
                numStr2: "",
                numStr3: "",
                totalFee: "",
                standard: "",
                unit: ""
            });
        },
        handleInsert(row) {
            var billPushGoodsList = this.formModel.billPushGoodsList;
            var index = billPushGoodsList.indexOf(row);

            if(index>=0){
                billPushGoodsList.splice(index+1,0,{
                    sortNo: index + 2,
                    goodsId: "",
                    beginEndTime: null,
                    numStr: "",
                    numStr2: "",
                    numStr3: "",
                    totalFee: "",
                    standard: "",
                    unit: ""
                });
            }
        },
        handleDelete(row) {
            var billPushGoodsList = this.formModel.billPushGoodsList;

            billPushGoodsList.splice(billPushGoodsList.indexOf(row), 1);

            this.formModel.removeBillPushGoodsList.push(row);
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];

            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                
                const values = data.map(item => Number(item[column.property]));

                if (!values.every(value => isNaN(value))) {
                    sums[5] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);

                    sums[5] = this.fmtMoney(sums[5]);
                } else {
                    sums[index] = '';
                }
            });

            return sums;
        },
        fmtMoney(value){
            const num = Number(value);
            
            if (!isNaN(num)) {
                return num.toFixed(2);
            }
            else{
                return num;
            }
        },
        handGoodsChange(scope){
            var row = scope.row;
            
            // setTimeout(()=>{                
            var formData = new FormData();
            formData.append("id", row.goodsId);
            formData.append("companyId", this.formModel.companyId);

            companyPaymentApi.loadDetails(formData).then((response)=>{
                var jsonData = response.data;

                if(jsonData.result){
                    var data = jsonData.data;

                    // row.standard = data.unitPriceStr;
                    // row.unit = data.unitPrice;
                    // row.goodTypeId = data.goodType;
                    this.$set(row,"standard",data.unitPriceStr);
                    this.$set(row,"unit",data.unit);
                    this.$set(row,"unitPrice",data.unitPrice);
                    this.$set(row,"goodTypeId",data.goodType);

                    //如果是电费会返回起码
                    this.$set(row,"numStr", data.numStr);
                }
            });
            // },100);
        },
        handNumChange(scope){
            var row = scope.row;

            var num = row.numStr3;
            var unitPrice = row.unitPrice;
            var totalFee = num*1*unitPrice*1;

            if(totalFee!=null){
                this.formModel.billPushGoodsList[scope.$index].totalFee = Math.round(totalFee).toFixed(2); //
            }
        },
        handStartChange(scope){
            var row = scope.row;

            var num1=row.numStr;
            var num2=row.numStr2;
            var unitPrice = row.unitPrice;

            setTimeout(()=>{         
                if(num1!=null&&num2!=null){
                    var num3 = num2-num1;

                    this.formModel.billPushGoodsList[scope.$index].numStr3 = num3;

                    var totalFee = num3*1*unitPrice*1;

                    if(totalFee!=null){
                        this.formModel.billPushGoodsList[scope.$index].totalFee = Math.round(totalFee).toFixed(2); //
                    }
                }
            },100);
        }
    },
    mounted: function() {
        var self = this;

        (function() {
        if (self.businessKey.length == 0) {
            return billPushApi.create();
        } else {
            return billPushApi.edit(self.businessKey);
        }
        })()
        .then(response => {
            var jsonData = response.data;
            self.loading = false;

            if (jsonData.result) {

                self.formModel = jsonData.data;

                var billPushGoodsList = self.formModel.billPushGoodsList;

                for(var i=0;i<billPushGoodsList.length;i++){
                    var beginEndTime = [];
                    beginEndTime.push(billPushGoodsList[i].beginDate);
                    beginEndTime.push(billPushGoodsList[i].endDate);
                    billPushGoodsList[i].beginEndTime = beginEndTime;
                }

                if(jsonData.data.checkinInfoId!=null&&jsonData.data!=""){
                    this.handleCompanyChange(jsonData.data.checkinInfoId);
                }
            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.input-form-main{
    width: 300px;
}
.link-span{
    margin-right: 20px;
}
.el-form-item{
    margin-bottom: 25px;
}
.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}
.user-panel {
  margin: 10px auto;
}

.compact-table{
    .el-form-item {
        margin-bottom: 5px;
    }
}
</style>