<template>
    <el-dialog
    :visible.sync="showDialog"
    :title="title"
    width="70%"
    :modal-append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    
  >
        <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" label-width="150">
                    <el-row :gutter="20" >
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <span class="fontText">账单名称</span> 
                                <span class="data">{{formModel.name}}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">收款方</span> 
                            <span class="data">{{formModel.payee}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">收款账户</span> 
                            <span class="data">{{formModel.collectionAccount}}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" >
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <span class="fontText">缴费单位</span> 
                                <span class="data">{{formModel.companyName}}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText" >推送用户</span> 
                            <span class="data" >{{formModel.personName}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">推送状态</span> 
                            <span class="data" v-if="formModel.status=='0'" style="color:#F56C6C">未推送</span>
                            <span class="data" v-else-if="formModel.status=='1'" style="color:#67C23A">
                                <span v-if="formModel.payStatus=='20'" style="color:#67C23A">已完成</span>
                                <span v-else style="color:#67C23A">已推送</span>
                            </span>
                            <span class="data" v-else-if="formModel.status=='2'" style="color:#E6A23C">推送失败</span>
                            <span class="data" v-else-if="formModel.status=='3'" style="color:#909399">已作废</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" >
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <span class="fontText">推送时间</span> 
                                <span class="data">{{formModel.pushTime}}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText" >缴费状态</span> 
                            <span class="data" v-if="formModel.payStatus=='10'" style="color:#F56C6C">未支付</span>
                            <span class="data" v-if="formModel.payStatus=='15'" style="color:#E6A23C">部分支付</span>
                            <span class="data" v-else-if="formModel.payStatus=='20'" style="color:#67C23A">已支付</span>
                            <span class="data" v-else-if="formModel.payStatus=='30'" style="color:#909399">已关闭</span>
                            <span class="data" v-else-if="formModel.payStatus=='40'" style="color:#909399">已退款</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">支付方式</span> 
                            <el-link class="data"  type="primary" v-if="formModel.payStatus=='10'" @click="handlePayTime">{{formModel.payName}}</el-link>
                            <span class="data" v-else>{{formModel.payName}}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" >
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <span class="fontText">支付时间</span> 
                                <span class="data">{{formModel.payTime}}</span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">账单备注</span> 
                            <span class="data">{{formModel.bak}}</span>
                        </el-col>
                        <el-col :span="8">
                            <span class="fontText">开票类型</span> 
                            <span class="data">{{formModel.invoiceTypeN}}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" >
                        <el-col :span="8">
                            <div class="grid-content bg-purple">
                                <span class="fontText">是否开票</span> 
                                <span class="data">
                                    <el-switch
                                        v-model="formModel.invoiced"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        @change="handleSwitchChange(formModel,'2')">
                                    </el-switch>
                                </span>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple" v-if="formModel.isMobilePayment">
                                <span class="fontText">微信支付</span> 
                                <span class="data">
                                    <el-switch
                                        v-model="formModel.isOpenWechat"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949"
                                        @change="handleSwitchChange(formModel,'0')">
                                    </el-switch>
                                </span>
                            </div>
                        </el-col>
                        <el-col :span="8" v-if="formModel.isMobilePayment">
                            <span class="fontText">支付宝支付</span> 
                            <span class="data">
                                <el-switch
                                    v-model="formModel.isOpenAlipay"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    @change="handleSwitchChange(formModel,'1')">
                                </el-switch>
                            </span>
                        </el-col>
                    </el-row>
                 </el-form>
            </div>
            <el-divider></el-divider>
            <el-row class="button-group" >
                <el-button type="success" size="small" v-if="formModel.status=='1'" @click="handleQucodePay(formModel.orderId)">付款码</el-button>
                <el-button type="success" size="small" plain icon="el-icon-download" @click="exportXls()">导出账单</el-button>
                <el-button
                    type="primary"
                    size="small"
                    plain
                    icon="el-icon-edit"
                    @click="handleEdit" 
                    :disabled="formModel.status=='1'"
                    >修改账单</el-button
                >
                <el-button
                    type="warning"
                    size="small"
                    plain
                    icon="el-icon-s-promotion"
                    @click="handlePush(row)"
                    >推送账单</el-button
                >
            </el-row>
            <el-table :data="tableData" v-loading="loading" stripe  show-summary :summary-method="getSummaries">
                <el-table-column type="index" label="序号" width="80"></el-table-column>
                <el-table-column prop="goodsName" label="收费项目"></el-table-column>
                <el-table-column prop="beginEndTime" label="起止时间" width="300"></el-table-column>
                <el-table-column prop="num" label="数量" width="300"></el-table-column>
                <el-table-column prop="standard" label="收费标准（元）"></el-table-column>
                <el-table-column prop="totalFee" label="收费金额（元）">
                    <template slot-scope="{row}">
                        {{fmtMoney(row.totalFee)}}
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination style="text-align: center;"
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
            <billPush-detail
                v-if="showModal"
                :businessKey="businessKey"
                :title="modalTitle"
                @close="onDetailModalClose"
            ></billPush-detail>
            <billPush-order-payTime
                v-if="showModal2"
                :businessKey="businessKey"
                :title="modalTitle2"
                @close="onDetailModalClose2"
            ></billPush-order-payTime>
            <billPush-qrcode-pay
                v-if="showModal3"
                :title="modalTitle3"
                :orderId="orderId"
                @close="onDetailModalClose3"
            ></billPush-qrcode-pay>

    </el-dialog>
</template>
<script>
import Constant from "@/constant";
import billPushApi from "@/api/business/billPush";
import billPushPersonApi from "@/api/business/billPushPerson";
import billPushDetail from "./billPush-detail";
import billPushOrderPayTime from "./billPush-order-payTime";
import billPushQrcodePay from "./billPush-qrcode-pay";
export default {
    props: ["businessKey", "title"],
    data() {
        return{
            formModel:{

            },
            queryModel: {
                name: "",
                status: "",
                pushTimeRange: ['',''],
                companyId:"",
                payService:"",
            },
            formModel2:{
                id:"",
                payName:"",
                payTime:"",
            },
            payModal:true,
            payTime:"",
            tableData:[],
            pageIndex: 1,
            pageSize: 10,
            totalPages: 0,
            totalElements: 0,
            pageSizeList: [10, 20, 30],
            multipleSelection: [],
            activeName:"first",
            showDialog: true,
            loading: false,
            showModal: false,
            showModal2:false,
            showModal3:false,
            submitting: false,
            modified: false
        }
    },
    methods:{
        closeDialog() {
            this.$emit("close", this.modified);
        },
        handleClick(){

        },
        handleEdit() {
            this.modalTitle = "编辑";
            this.operation = "edit";
            this.businessKey = this.businessKey;
            this.showModal = true;
        },
        handleQucodePay(orderId){
            this.modalTitle3 = "付款码";
            this.operation = "edit";
            this.orderId = orderId;
            this.showModal3 = true;
        },
        changePage(pageIndex,exportFlag) {
            var self = this;
            self.loading = true;

            var billPushId = self.businessKey;

            self.pageIndex = pageIndex;
            var formData = new FormData();

            formData.append("billPushId", billPushId);
            formData.append("pageIndex", self.pageIndex);
            
            
            if(exportFlag!=null){
                formData.append("exportFlag", exportFlag);
                formData.append("pageSize", 10000);
            }
            else{
                formData.append("pageSize", self.pageSize);
            }


            billPushApi.goodsPageList(formData)
            .then(function(response) {
                self.loading = false;

                var jsonData = response.data;

                if(jsonData.result) {
                    if(exportFlag){
                        if(document.location.href.startsWith("https://")){
                            jsonData.data = jsonData.data.replace("http://","https://");
                        }

                        //导出
                        self.$message({
                            showClose: true,
                            type: "success",
                            message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                            dangerouslyUseHTMLString: true,
                            duration: 30000
                        });
                    }
                    else{
                        //分页查看
                        var page = jsonData.data;

                        self.tableData = page.data;
                        self.totalPages = page.totalPages;
                        self.totalElements = page.recordsTotal;
                    }
                }
                else{
                    self.$message.warning(jsonData.message);
                }

                // self.tableData = jsonData.data;
                // self.totalPages = jsonData.totalPages;
                // self.totalElements = jsonData.recordsTotal;
            })
            .catch(error => {
                self.loading = false;
                // self.$message.error(error + "");
            }); 
        },
        fmtMoney(value){
            const num = Number(value);
            
            if (!isNaN(num)) {
                return num.toFixed(2);
            }
            else{
                return num;
            }
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;
            //this.closeDialog();
            this.modified = retObj;
            
            if(retObj){
                this.changePage(1,false);
            }
        },
        exportXls() {
            this.changePage(1,true);
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[5] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                    
                    sums[5] = this.fmtMoney(sums[5]);
                } else {
                    sums[index] = '';
                }
            });

            return sums;
        },
        onDetailModalClose2(){
            //保存成功后回调
            this.showModal2 = false;
            var self = this;

            (function() {
                if (self.businessKey.length == 0) {
                    return billPushApi.createGoods();
                } else {
                    return billPushApi.detail(self.businessKey);
                }
            })()
            .then(response => {
                var jsonData = response.data;
                self.loading = false;

                if (jsonData.result) {
                    self.formModel = jsonData.data;
                } else {
                    self.$message.error(jsonData.message + "");
                }
            })
            .catch(error => {
                self.$message.error(error + "");
            });

        },
        onDetailModalClose3(retObj) {
            //保存成功后回调
            this.showModal3 = false;
            //this.closeDialog();
            this.modified = retObj;
            
            if(retObj){
                this.changePage(1,false);
            }
        },
        handlePayTime(){
            var self = this;

            var billPushId = self.businessKey;

            this.modalTitle2 = "账单推送详情";
            this.operation = "detail";
            this.businessKey = billPushId;
            this.showModal2 = true;
        },
        handlePush(){
            var self = this;

            self.$confirm("是否确认推送账单?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
            })
            .then(() => {
            
                var formData = new FormData();
                formData.append("id", self.businessKey);

                billPushApi.pushBill(formData).then((response)=>{
                    var jsonData = response.data;

                    if (jsonData.result) {
                        this.formModel.status="1";
                        this.$message.success("账单消息推送成功！");
                    } else {
                        this.$message.error(jsonData.message + "");
                    }

                });
            });
        },        
        handleSwitchChange(record,type){
            var self = this;

            var formData = new FormData();
            self.loading = true;

            formData.append("id", self.businessKey);

            if(type=="0"){
                formData.append("isOpen", record.isOpenWechat);
            }
            else if(type=="1"){
                formData.append("isOpen", record.isOpenAlipay);
            }
            else if(type=="2"){
                formData.append("isOpen", record.invoiced);
            }

            formData.append("type", type);

            billPushApi.updateIsOpenWechatOrAlipay(formData).then(function (response) {
                var jsonData = response.data;
                self.loading = false;
                if (jsonData.result) {
                    //self.changePage(self.pageIndex);
                    self.modified = true;
                }
                else{
                    self.$message.error(jsonData.message + "");
                }
            });

        }
    },
    components: {
        "billPush-detail": billPushDetail,
        "billPush-order-payTime":billPushOrderPayTime,
        "billPush-qrcode-pay":billPushQrcodePay
    },
    mounted: function() {
        var self = this;

        (function() {
            if (self.businessKey.length == 0) {
                return billPushApi.createGoods();
            } else {
                return billPushApi.detail(self.businessKey);
            }
        })()
        .then(response => {
            var jsonData = response.data;
            self.loading = false;

            if (jsonData.result) {
                self.formModel = jsonData.data;
            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });

        this.changePage(1,false);
    }
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
  margin-left: 20px;
}
.data{
    margin-left: 50px;
    font-weight:bold
}
.el-row {
margin-bottom: 20px;
}
.el-col {
border-radius: 4px;
}
.grid-content {
border-radius: 4px;
min-height: 36px;
}
.title1{
    margin-bottom: 40px;
}
.title2{
    margin-top: 40px;
    margin-bottom: 40px;
}
</style>