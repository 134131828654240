<template>
        <el-dialog
            title="线下支付时间"
            :visible.sync="showDialog"
            :modal-append-to-body="true"
            style="text-align: left;"
            width="30%"
            @close="closeDialog"
            append-to-body
            :close-on-click-modal="false"
            >
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" label-width="100px" :model="formModel" :rules="ruleValidate">
                    <el-form-item label="支付方式" prop="payName">
                        <el-select v-model="formModel.payName" style="width:300px"  >
                            <el-option
                                v-for="payName in payNameList"
                                :key="payName.id"
                                :label="payName.name"
                                :value="payName.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="收款时间" prop="payTime">
                        <el-date-picker
                            style="width:300px"
                            v-model="formModel.payTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleSubmit" :loading="submitting">保存</el-button>
                </span>
            </el-dialog>
</template>
<script>
import Constant from "@/constant";
import billPushApi from "@/api/business/billPush";
export default {
    props: ["businessKey", "title"],
    data(){
        return{
            ruleValidate: {
                payName: [{ required: true, message: "支付方式不能为空", trigger: "blur" }],
                payTime: [{ required: true, message: "收款时间不能为空", trigger: "blur" }],
            },
            formModel:{
                payName:"",
                payTime:"",
            },
            loading:false,
            showDialog:true,
            submitting: false,
            payNameList:[
                {
                    id:"wechat",
                    name:"微信",
                },
                {
                    id:"alipay",
                    name:"支付宝",
                },
                {
                    id:"transfer",
                    name:"对公转账"
                },
                {
                    id:"cash",
                    name:"现金支付"
                },
                {
                    id:"individual",
                    name:"个人账户"
                }
            ],
        }
    },
    methods:{
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit(){
            var self = this;

            this.$refs["form"].validate(valid => {
                if (valid) {
                (function() {
                   
                    var id = self.businessKey

                    return billPushApi.updateOrder(self.formModel);
                    
                })().then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                        self.$message({
                            message: "保存成功!",
                            type: "success"
                        });

                        self.$emit("close", {result:true});
                    } else {
                        self.$message({
                            message: jsonData.message + "",
                            type: "warning"
                        });

                        self.$emit("close", {result:false});
                    }
                });
                }
            });
        }
    },
    mounted: function() {
        var self = this;

        (function() {
            if (self.businessKey.length == 0) {
                return billPushApi.createPayTime(self.businessKey);
            } else {
                return billPushApi.editPayTime(self.businessKey);
            }
        })()
        .then(response => {
            var jsonData = response.data;
            self.loading = false;

            if (jsonData.result) {

                self.formModel = jsonData.data;

                if (jsonData.result) {
                    self.formModel = jsonData.data;
                } else {
                    self.$message.error(jsonData.message + "");
                }

            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
<style lang="stylus" scoped>

</style>
  
